<template>
  <basic-page-layout>
    <template v-if="regionData && placeData">

      <div v-if="placeData['epapp:components'] && placeData['epapp:components'].length"
           :class="marginClass">
        <div v-for="(widget, key) in placeData['epapp:components']"
             :key="'tour-widget'+key">
          <widget-selector :type="widget['@type']" :widget-content="widget"></widget-selector>
        </div>
      </div>
      <div v-else>
        <p class="ion-text-center">
          {{ $t('placeDetails.noPlaceDataAvailable') }}
        </p>
      </div>
    </template>
  </basic-page-layout>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BasicPageLayout from '@/views/basic-page-layout.vue';
import {mapState} from "vuex";
import {AppState} from "@/store-management/vuex";
import WidgetSelector from '@/widgets/widget-selector.vue';

export default defineComponent({
  name: 'PlaceDetailsView',
  extends: BasicPageLayout,
  components: {
    BasicPageLayout,
    WidgetSelector
  },

  computed: {
    tourId(): string {
      return this.$route.params.tour?.toString();
    },
    placeId(): string {
      return this.$route.params.place?.toString();
    },
    ...mapState({
      tourData(state: AppState) {
        return state.tours[this.tourId] || null;
      }
    }),
    placeData() {
      if (this.tourData && this.tourData["epapp:places"] && this.tourData["epapp:places"].length) {
        return this.tourData["epapp:places"].filter((place: any) => (place['@id'] === this.placeId))[0] || null
      }
      return null
    },
    marginClass() {
      if (this.placeData['epapp:components'][0]['@type'] === 'epapp:ImageFeature') {
        return 'ion-margin-bottom';
      }
      return 'ion-margin-vertical';
    }
  },

  data() {
    return {}
  },

});
</script>

<style scoped lang="scss">

</style>
